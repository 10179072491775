import { useState } from "react";

export const useCounter = (maxValue: number) => {
  const [value, setValue] = useState(0);

  function increase() {
    setValue((value + 1) % (maxValue + 1));
  }

  function decrease() {
    setValue((value - 1 + maxValue + 1) % (maxValue + 1));
  }

  return { increase, decrease, value };
};
