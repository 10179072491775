import React from "react";
import ProfilePic from "./assets/profile_pic.jpg";
import GitHubLogo from "./assets/github-mark-white.svg";
import LinkedInLogo from "./assets/icons8-linkedin.svg";
import InstagramLogo from "./assets/icons8-instagram.svg";
import YouTubeLogo from "./assets/youtube-svgrepo-com.svg";
import ChevronLeft from "./assets/chevron_left_FILL0_wght400_GRAD0_opsz24.svg";
import ChevronRight from "./assets/chevron_right_FILL0_wght400_GRAD0_opsz24.svg";
import { useCounter } from "./hooks/Counter";
import "./App.css";

function App() {
  const jobTitles = ["software developer", "photographer & videographer"];
  const { increase, decrease, value } = useCounter(jobTitles.length - 1);

  return (
    <div className="App">
      <header className="App-header">
        <img
          className="profile-pic"
          src={ProfilePic}
          alt="Portrait of Dániel"
        />
        <h1>Dániel Juhász</h1>
        <div className="job-title-container">
          <img
            className="chevron chevron-left"
            src={ChevronLeft}
            alt="Chevron left"
            onClick={decrease}
          />
          <p className="job-title">{jobTitles[value]}</p>
          <img
            className="chevron chevron-right"
            src={ChevronRight}
            alt="Chevron right"
            onClick={increase}
          />
        </div>
      </header>
      <main>
        {value === 0 && (
          <div className="contacts">
            <a
              href="https://github.com/DanShepherd27"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="github-logo"
                src={GitHubLogo}
                alt="GitHub logo"
              ></img>
            </a>
            <a
              href="https://www.linkedin.com/in/danijuhasz27/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="linkedin-logo"
                src={LinkedInLogo}
                alt="LinkedIn logo"
              ></img>
            </a>
          </div>
        )}

        {value === 1 && (
          <div className="contacts">
            <a
              href="https://instagram.com/danijuhasz27"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="instagram-logo"
                src={InstagramLogo}
                alt="Instagram logo"
              ></img>
            </a>
            <a
              href="https://www.youtube.com/@danijuhasz27"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="linkedin-logo"
                src={YouTubeLogo}
                alt="YouTube logo"
              ></img>
            </a>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;
